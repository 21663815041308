import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Emere Renguard is a `}<a parentName="p" {...{
        "href": "/Protoxy",
        "title": "Protoxy"
      }}>{`Protoxy`}</a>{` for `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{`. Young and hot-tempered with auburn hair and a youthful face, Emere is a fierce and loyal member of the Church of the Luminary. He will stop at nothing to defend the sanctity of the Church, and certainly enjoys putting down anyone who comes even close to disrespecting his authority, and by extension the Church’s.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      